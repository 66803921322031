@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Raleway', sans-serif;
  width: 100vw;
  color: #302937;
  background-color: rgb(249, 249, 249);
  overflow-x: hidden;
  font-variant-numeric: lining-nums;
}

h1 {
  font-size: 3em;
  font-weight: 500;
}

p {
  line-height: 1.5em;
}

.centered {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

address {
  font-style: normal;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.logo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  padding: 1em 0 1em 0;
}

.logo .top {
  font-size: 1.65em;
  font-weight: 700;
}

.logo .bottom {
  font-size: 0.9em;
}

@media screen and (min-width: 600px) {
  .logo .top {
    font-size: 2em;
  }
  
  .logo .bottom {
    font-size: 1.1em;
  }
}

::-moz-selection {
  background: #c340ff35;
}

::-webkit-selection {
  background: #c340ff35;
}

::selection {
  background: #c340ff35;
}

