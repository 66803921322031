#about {
    background-color: #2E1448;
    color: rgb(249, 249, 249);
}

#about h2 {
    margin-bottom: 0;
}

#about p {
    margin-top: 0.5em;
}