#image-divider {
    background-image: url(../Common/windows.jpg);
    background-size: cover;
    height: 15em;
}

@media screen and (min-width: 600px) {
    #image-divider {
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: center center;
    }
}