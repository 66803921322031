#welcome {
    background-image: url(../Common/windows.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-shadow: 0 0 5px rgb(0, 0, 0);
    text-align: center;
}

#welcome .section-content {
    align-items: center;
}

#welcome h1 {
    font-size: 2.5em;
    margin-top: 1.5em;
}
