#navbar {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.141);
    display: flex;
    flex-direction: column;
    position: sticky; 
    top: 0;
    z-index: 100;
}

#navbar>div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    column-gap: 1.2em;
}

#navbar a {
    cursor: pointer;
}

#navbar a:hover {
    text-shadow: 0 0 0.7px;
}

.links {
    height: 40px;
    background-color: #f9f9f9;
}


#navbar-contact {
    background-color: #2E1448;
    height: 40px;
    padding-top: 0.1em;
    font-size: 0.8em;
}

#navbar-contact a {
    color: rgb(249, 249, 249);
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5em;
    padding-inline: 0.5em;
}

#navbar-contact img {
    width: 1em;
}

@media screen and (min-width: 600px) {
    #navbar-contact {
        font-size: 1em;
    }

    #navbar>div {
        column-gap: 1.6em;
    }
}

@media screen and (max-width: 350px) {
    #navbar .links * {
      font-size: smaller;
    }
}