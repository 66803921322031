#footer {
    height: 12em;
    background-color: #2E1448;
    color: rgb(249, 249, 249);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: smaller;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.141);
    margin-top: 1em;
}

#footer a {
    color: rgb(249, 249, 249);
    font-weight: 500;
    font-size: 1.5em;
    text-decoration: none;
}