.numberContainer {
    width: 60px;
    height: 60px;
    background-color: #2E1448;
    color: #F9F9F9;
    font-weight: 700;
    font-size: 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-variant-numeric: lining-nums;
    margin: 20px 0 20px 0;
}