#services ul {
    list-style: none;
    padding-left: 0;
    margin-block: 0;
}

#services li {
    padding-block: 0.2em;
    margin-block: 0.2em;
    font-size: 1.2em;
}

.decorated-list {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
}

.decor {
    background-color: #4D1F79;
    width: 0.2em;
    margin-inline: 1em;
}

.tile .title {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1em;
    margin: 0.5em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    flex: 1 1 0;
    min-width: 150px;
}

@media screen and (min-width: 600px) {
    #products>div:nth-child(even) .decorated-list {
        flex-direction: row-reverse;
    }

    #products>div:nth-child(even) ul, #products>div:nth-child(even) h2 {
        text-align: right;
    }

    .decor {
        margin-inline: 2em;
    }
}

