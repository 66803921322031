#header {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

#header img {
    padding: 0.5em 0.5em 0.5em 0;
    height: 2.5em 
}

#header .logo .top {
    color: #2E1448;
}

hr {
    border: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.200);
    width: 800px;
    max-width: 100%;
    margin: 0;
}


@media screen and (min-width: 600px) {
    #header img {
        padding: 0.5em 0.5em 0.5em 0;
        height: 3em 
    }
}

@media screen and (max-width: 350px) {
    #header * {
      font-size: smaller;
    }
}