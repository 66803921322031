section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.section-content {
    display: flex;
    flex-direction: column;
    max-width: 800px;
}

.section-content:not(.full) {
    width: 80%;
    padding: 2em;
}

.section-content.full {
    width: 100%;
    padding: 2em 0 2em 0;
}

@media screen and (min-width: 600px) {
    .tile:hover {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    }
}

