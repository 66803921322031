#contact-data {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 1em;
}

#contact-data>div, #contact-data>div>a {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #302937;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
}

#contact img {
    width: 80px;
    margin: 1em;
}

@media screen and (min-width: 600px) {
    #contact a:hover {
        color: #4D1F79;
    }
    
    #contact a:hover>img {
        filter: brightness(150%);
    }

    #contact-data {
        flex-direction: row;
        column-gap: 1em;
    }

    #contact-data * {
        flex-basis: 100%;
    }
}
